import {
  GET_STORE_LISTING,
  GET_CONCEPT_LISTING,
  GET_RETAILER_LISTING,
  PUT_STORE_DATA,
  GET_CATEGORY_LISTING,
  PUT_CATEGORY_DATA,
  GET_SCAN_MASTER,
  GET_SCAN_PRODUCT,
  GET_QUANTITY_LISTING,
  GET_DISCOUNT_LISTING,
  DELETE_CATEGORY_DATA,
  GET_STORE_PRODUCTS,
  GET_PRODUCT_SEARCH,
  GET_FILTERED_PRODUCT,
  GET_SCAN_PRODUCT_EXTRACT,
  GET_SCAN_PRODUCT_EXTRACT_NEW,
  SEARCH_PRODUCT_SCAN,
  SEARCH_SCAN_MASTER,
  GET_LOGIN_USER_ROLE,
} from "./constants";

const initialState = {
  storeListing: [],
  scanMaster: [],
  scanProducts: [],
  searchScanMasterData: [],
  conceptDataArray: [],
  retailerDataArray: [],
  categoryListing: [],
  quantityUnitData: [],
  discountData: [],
  selectedStore: "",
  productDetails: "",
  connectivity: true,
  productMasterData: [],
  loginUserRole: "",
};

const storeProductReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_STORE_LISTING:
      return { ...state, storeListing: action.data };
    case GET_SCAN_MASTER:
      return { ...state, scanMaster: action.data };
    case GET_CATEGORY_LISTING:
      return { ...state, categoryListing: action.data };
    case GET_CONCEPT_LISTING:
      return { ...state, conceptDataArray: action.data };
    case GET_RETAILER_LISTING:
      return { ...state, retailerDataArray: action.data };
    case GET_QUANTITY_LISTING:
      return { ...state, quantityUnitData: action.data };
    case GET_DISCOUNT_LISTING:
      return { ...state, discountData: action.data };
    case GET_STORE_PRODUCTS:
      return { ...state, productMasterData: action.data };
    case SEARCH_PRODUCT_SCAN:
      return { ...state, scanProducts: action.data };
    case SEARCH_SCAN_MASTER:
      return { ...state, searchScanMasterData: action.data };
    case PUT_STORE_DATA:
      return { ...state };
    case PUT_CATEGORY_DATA:
      return { ...state };
    case GET_SCAN_PRODUCT:
      return { ...state, scanProducts: action.data };
    case DELETE_CATEGORY_DATA:
      return { ...state };
    case GET_PRODUCT_SEARCH:
      return { ...state };
    case GET_FILTERED_PRODUCT:
      return { ...state };
    case GET_SCAN_PRODUCT_EXTRACT:
      return { ...state };
    case GET_SCAN_PRODUCT_EXTRACT_NEW:
      return { ...state };
    case GET_LOGIN_USER_ROLE:
      return { ...state, loginUserRole: action.data };
    default:
      return state;
  }
};

export default storeProductReducer;
