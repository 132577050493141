import React from "react";
import "../CustomModal.css";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  putCategoryData,
  getStoreListing,
  getCategoryListing,
  putUserPermissionData,
  getUserPermissionData,
} from "../../../../Services/Store&Product/action";
import { colorConstants } from "../../../../config/constants";
import Utils from "../../../../Utils/Utils";

const { translate } = Utils;
class UserPermissionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      formControl: {
        name: React.createRef(),
        role: React.createRef(),
        concepts: React.createRef(),
      },
      errorFlag: false,
      errorFlagCategory: false,
      errorRecreateId: true,
      errorMessage: false,
      selectedRole: null,
    };
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    const { formControl } = this.state;
    const {
      putCategoryData,
      selectedItem,
      handleClose,
      getCategoriesData,
      putUserPermissionData,
      getUserPermissionChangeData,
    } = this.props;

    // console.log("selctedItem ===========>>>>>>>>", selectedItem);
    let payload = {
      userName: formControl.name.current.value,
      userRole: formControl.role.current.value,
      userConcept: formControl?.concepts?.current?.value,
    };
    this.setState({
      errorFlagCategory: false,
      errorFlag: false,
      errorRecreateId: false,
      errorMessage: false,
    });
    this.props.loginUserEditName(formControl.name.current.value);
    putUserPermissionData(
      payload,
      this.props.loginUserPermisionName === formControl.name.current.value,
      (status, response) => {
        if (status) {
          getUserPermissionChangeData();
          handleClose();
        } else {
          if (response && response.status === 404) {
            try {
              response.json().then((res) => {
                // console.log("res =======>>>>>>>>", res);
                if (res.details && res.details[0]) {
                  if (res.details[0].includes("deleted")) {
                    this.setState({
                      errorFlagCategory: true,
                      errorRecreateId: true,
                    });
                    return;
                  }
                  if (res.details[0].includes("numeric")) {
                    var message = translate.pleaseEnterNumberText;
                    this.setState({
                      errorFlagCategory: true,
                      errorRecreateId: true,
                      errorMessage: message,
                    });
                    return;
                  }
                  if (res.details[0].includes("id")) {
                    this.setState({
                      errorFlagCategory: true,
                      errorRecreateId: false,
                    });
                  } else {
                    this.setState({ errorFlag: true, errorRecreateId: false });
                  }
                }
              });
            } catch (error) {
              // console.log(error)
            }
          }
        }
      }
    );
  };
  render() {
    const {
      formControl,
      errorFlag,
      errorFlagCategory,
      errorRecreateId,
      errorMessage,
    } = this.state;
    const { showModal, handleClose, selectedItem, conceptListingData, roles } =
      this.props;

    return (
      <>
        <Modal
          dialogClassName="my-modal"
          show={showModal}
          onHide={() => {
            handleClose();
            this.setState({
              errorFlag: false,
              errorFlagCategory: false,
              errorMessage: false,
              selectedRole: null,
            });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            class="modal-header"
            closeButton
            onHide={() => {
              this.setState({
                errorFlag: false,
                errorFlagCategory: false,
                errorMessage: false,
                selectedRole: null,
              });
            }}
          >
            <Modal.Title className="header">
              {selectedItem && translate.editUserPermission}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm={2}>
                  {translate.nameText}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    style={{ width: "90%", height: 50 }}
                    required
                    ref={formControl.name}
                    defaultValue={selectedItem.userName || ""}
                    type="text"
                    placeholder="Name"
                    readOnly // This makes the field non-editable
                  />
                  {errorFlag ? (
                    <p
                      style={{
                        fontSize: 14,
                        color: colorConstants.redColor,
                        paddingTop: 10,
                      }}
                    >
                      {translate.customErrorCategoryNameText}
                    </p>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="roles">
                <Form.Label column sm={2}>
                  {"Role"}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    style={{ width: "90%", height: 50 }}
                    onChange={(e) => {
                      const selectedRole = e.target.value; // Get the selected role value
                      this.setState({ selectedRole: selectedRole }); // Update the state with the selected value

                      // this.setState({ conceptListingData });
                    }}
                    required
                    selected={selectedItem.roles || ""}
                    ref={formControl.role}
                    as="select"
                  >
                    {roles?.roles?.map((item, index) => {
                      console.log(
                        "item ++++++++++++++++++++++++++++++++++++++++",
                        item
                      );
                      return (
                        <option
                          key={item.id} // Use item.id for unique key instead of index
                          selected={selectedItem.roles === item.name} // Compare selectedItem.roles with item.name
                          value={item.name} // Use item.name as the value
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              {(selectedItem.roles === "ADMIN" &&
                this.state.selectedRole === null) ||
              (selectedItem.roles === "SUPER_ADMIN" &&
                this.state.selectedRole === null) ||
              this.state.selectedRole === "ADMIN" ||
              this.state.selectedRole === "SUPER_ADMIN" ? null : (
                <Form.Group as={Row} controlId="concept">
                  <Form.Label column sm={2}>
                    {translate.conceptText}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      style={{ width: "90%", height: 50 }}
                      // onChange={() => {
                      //   this.setState({ conceptListingData });
                      // }}
                      required
                      selected={selectedItem.concepts || ""}
                      ref={formControl.concepts}
                      as="select"
                    >
                      {roles?.concepts?.map((item, index) => {
                        // console.log("item +++++++++++++>>>>>>>>>", item);
                        return (
                          <option
                            key={index}
                            selected={selectedItem.concepts === item.name}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} style={{ paddingTop: 10 }}>
                <Form.Label column sm={2}></Form.Label>
                <Col sm={2}>
                  <Button
                    // onClick={this.props.updateLoginUserPermisionRole(
                    //   this.state.selectedRole
                    // )}
                    type="submit"
                    className="btn save-store"
                    style={{
                      width: 100,
                      fontWeight: "bold",
                      backgroundColor: colorConstants.themeColor,
                    }}
                  >
                    {translate.saveText}
                  </Button>
                </Col>
                <Col sm={2} style={{ paddingLeft: 40 }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        errorFlag: false,
                        errorFlagCategory: false,
                        errorMessage: false,
                        selectedRole: null,
                      });
                      handleClose();
                    }}
                    style={{
                      width: 100,
                      fontWeight: "bold",
                      backgroundColor: colorConstants.whiteColor,
                    }}
                  >
                    <span style={{ color: colorConstants.themeColor }}>
                      {translate.cancelText}
                    </span>
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //
});

const mapDispatchToProps = (dispatch) => ({
  putCategoryData: bindActionCreators(putCategoryData, dispatch),
  getListingData: bindActionCreators(getStoreListing, dispatch),
  getCategoriesData: bindActionCreators(getCategoryListing, dispatch),
  putUserPermissionData: putUserPermissionData,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPermissionModal)
);
