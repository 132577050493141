import React from "react";
// import "./DeleteModal.css";
// import "../CustomModal.css";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Utils from "../../../../Utils/Utils";
import { colorConstants } from "../../../../config/constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const { translate } = Utils;
class UserPermissionDeleteModal extends React.Component {
  handleSubmit = (event) => {
    const { confirmDelete } = this.props;
    event.preventDefault();
    event.stopPropagation();
    confirmDelete && confirmDelete();
  };

  render() {
    const {
      showModal,
      handleClose,
      reject,
      productIndex,
      showArticleName,
      productMasterData,
      errorRejectMessage,
    } = this.props;
    const multiRejectData = productMasterData.filter(
      (item) => item.selectedFlag
    );
    return (
      <>
        <Modal
          dialogClassName="delete-modal"
          show={showModal}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title className="header">
              {translate.confirmText}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group as={Row}>
                <Form.Label column sm={10} style={{ marginLeft: 3 }}>
                  {translate.confirmDelete}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} style={{ paddingTop: 10 }}>
                <Form.Label column sm={2}></Form.Label>
                <Col sm={2} style={{ marginRight: 50 }}>
                  <Button
                    onClick={handleClose}
                    style={{
                      width: 100,
                      fontWeight: "bold",
                      backgroundColor: colorConstants.whiteColor,
                    }}
                  >
                    <span style={{ color: colorConstants.themeColor }}>
                      {translate.noText}
                    </span>
                  </Button>
                </Col>
                <Col sm={2}>
                  <Button
                    type="submit"
                    className="btn save-store"
                    style={{
                      width: 100,
                      fontWeight: "bold",
                      backgroundColor: colorConstants.themeColor,
                    }}
                  >
                    {translate.yesCapText}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  productMasterData: state.storeReducer.scanProducts,
});

export default withRouter(connect(mapStateToProps)(UserPermissionDeleteModal));
