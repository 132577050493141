import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, FormControl, Form } from "react-bootstrap";
import "./UserPermission.css";
import Img from "react-image";
import Utils from "../../../Utils/Utils";
import { imageConstants } from "../../../config/constants";
import { sortBy } from "lodash";
import UserPermissionModal from "../../Common/Modal/UserPermissionModal/UserPermissionModal";
import {
  deleteUserPermissionData,
  getUserPermissionData,
  getUserPermissionSearchData,
  getUserRoles,
} from "../../../Services/Store&Product/action";
import CustomLoader from "../../Loader/Loader";
import { useHistory } from "react-router-dom";
import UserPermissionDeleteModal from "../../Common/Modal/UserPermissionDeleteModal/UserPermissionDeleteModal";

let selectedValue = {};
const { translate, ActivityIndicator } = Utils;
const UserPermission = (props) => {
  const [show, setShow] = useState(false);
  const [deleteModalFlag, setDeleteModal] = useState(false);
  const [userFlag, setUserFlag] = useState(false);
  const [userFilterValue, setUserFilterValue] = useState(null);
  const [scanMasterData, setScanMasterData] = useState([]);
  const [filterFlag, setFilterFlag] = useState(false);
  const [userRoleData, setUserRoleData] = useState();
  const [userPermissionData, setUserPermissionData] = useState();
  const [activityStartFlag, setActivityStartFlag] = useState(true);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleDeleteShow = () => setDeleteModal(true);
  const handleDeleteClose = () => setDeleteModal(false);
  const history = useHistory();

  const getUserPermissionaData = () => {
    getUserPermissionData((success, data) => {
      if (!success) {
        console.error("Failed to fetch user roles from api:", data);
      } else {
        setActivityStartFlag(false);
        setUserPermissionData(data);
      }
    });
  };

  // Effect to manage localStorage data for the filter
  useEffect(() => {
    const localUserFilter = JSON.parse(localStorage.getItem("localUserFilter"));
    if (localUserFilter) {
      setUserFilterValue(localUserFilter);
    }
    setUserFilterValue(null);
    setUserFlag(false);
    getUserPermissionaData();
    // deleteUserPermissionData();
    getUserRoles((success, data) => {
      if (!success) {
        console.error("Failed to fetch user roles from api:", data);
      } else {
        setUserRoleData(data);
        // console.log("User Roles:", data); // This should log the array directly
      }
    });
  }, []);

  const handleFilterChange = useCallback(
    (val) => {
      setUserFilterValue(val);
      setUserFlag(true);
      localStorage.setItem("localUserFilter", JSON.stringify(val));

      // Prepare payload and API call
      const payload = {
        username: val,
      };
      setUserFlag(false);

      getUserPermissionSearchData(payload, (success, data) => {
        if (!success) {
          console.error("Failed to fetch user roles from api:", data);
        } else {
          setUserPermissionData(data);
        }
      });
    },
    [getUserPermissionSearchData]
    // []
  );

  const handleRemoveFilter = useCallback(
    () => {
      localStorage.removeItem("localUserFilter");
      setUserFilterValue(null);
      setUserFlag(false);

      getUserPermissionaData();
    },
    [getUserPermissionaData]
    // []
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className="custom-header cursor"
      ref={ref} // forward the ref to the <span> element
      onClick={(e) => {
        e.preventDefault(); // prevent default action
        onClick(e); // trigger the onClick passed as prop
      }}
    >
      {children} {/* render the passed children */}
      <img
        style={{
          width: 12,
          marginLeft: 5,
        }}
        decode="false"
        src={imageConstants.filterIcon} // use the filter icon from imageConstants
        alt="Filter icon" // Add alt text for accessibility
      />
    </span>
  ));

  const CustomUserSearchMenu = (
    { children, style, className, Montserrat: labeledBy, setNameValue },
    ref
  ) => {
    const [value, setValue] = useState(""); // State hook should be called here

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto font-13"
          placeholder="Type to filter..." // Replace with your translation function if necessary
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setNameValue(value);
            }
          }}
          value={value}
        />

        <ul className="list-unstyled">
          <div className="scrollable-menu">
            {React.Children.toArray(children[0]).filter(
              (child) =>
                !value ||
                (child.props.children &&
                  child.props.children
                    .toLowerCase()
                    .includes(value.toLowerCase()))
            )}
          </div>
          {React.Children.toArray(children[1])}
        </ul>
      </div>
    );
  };

  // Forward the ref to the outer component
  const ForwardedCustomUserSearchMenu = React.forwardRef(CustomUserSearchMenu);

  return (
    <div className="bottom-div">
      <div className="d-flex flex-row justify-content-between">
        <p className="custom-store">{translate.userPermision}</p>
      </div>
      {
        <UserPermissionModal
          {...props}
          showModal={show}
          handleClose={handleClose}
          selectedItem={selectedValue}
          roles={userRoleData}
          getUserPermissionChangeData={getUserPermissionaData}
          loginUserPermisionRole={props?.loginUserPermisionRole}
          loginUserPermisionName={props?.loginUserPermisionName}
          loginUserEditName={props?.loginUserEditName}
        />
      }
      {
        <UserPermissionDeleteModal
          showModal={deleteModalFlag}
          handleClose={() => {
            handleDeleteClose();
          }}
          confirmDelete={() => {
            deleteUserPermissionData(
              selectedValue.userName,
              (status, response) => {
                console.log(status);
                if (status) {
                  getUserPermissionaData();
                  handleDeleteClose();
                }
              }
            );
            selectedValue = {};
          }}
        />
      }
      <table className="table table-borderless custom-table">
        <thead>
          <tr>
            <th scope="col" className="w-15">
              {translate.SerialNumberText}
            </th>
            <th scope="col">
              <Dropdown
                show={userFlag}
                onToggle={() => {
                  setUserFlag((userFlag) => !userFlag);
                }}
              >
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  {translate.UserText}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={ForwardedCustomUserSearchMenu}
                  setNameValue={handleFilterChange}
                >
                  {userFilterValue && (
                    <Dropdown.Item>{userFilterValue}</Dropdown.Item>
                  )}
                  {userFilterValue && (
                    <>
                      <div
                        className="removeButton"
                        onClick={handleRemoveFilter}
                      >
                        <span className="btn_apply" id="apply">
                          {translate.removeText}
                        </span>
                      </div>
                      <div className="bottomView" />
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th scope="col" className="w-20">
              {translate.RoleText}
            </th>
            <th scope="col" className="w-35">
              {translate.conceptText}
            </th>
            <th scope="col">{translate.actionText}</th>
          </tr>
        </thead>
        <tbody>
          {userPermissionData?.map((item, index) => {
            return (
              <tr
                key={index}
                className="shadow-sm rounded bg-white custom-border ml-1 mr-2 p-1 mb-2"
              >
                <td>{index + 1}</td>
                <td>{item.userName}</td>
                <td>{item.roles[0]}</td>
                <td>{item.concepts[0]}</td>
                <td className="action-css">
                  <div
                    className="Rounded-Rectangle-14 rounded cursor"
                    onClick={() => {
                      handleShow();
                      selectedValue = {
                        userName: item?.userName,
                        concepts: item?.concepts[0],
                        roles: item?.roles[0],
                      };
                    }}
                  >
                    <Img
                      decode="false"
                      src={imageConstants.editIcon}
                      className="Forma-1"
                    />
                  </div>
                  <div
                    className="Rounded-Rectangle-14-delete rounded cursor"
                    onClick={() => {
                      handleDeleteShow();
                      selectedValue = {
                        userName: item?.userName,
                        userConcept: item?.concepts[0],
                        userRole: item?.roles[0],
                      };
                    }}
                  >
                    <Img
                      decode={false}
                      src={imageConstants.deleteIcon}
                      className="Forma-1-delete "
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="scan-bottom-div custom-display">
        {activityStartFlag ? (
          <>
            <ActivityIndicator
              style={{
                marginLeft: 20,
                alignSelf: "center",
                marginTop: 150,
                height: 100,
                width: 100,
              }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default UserPermission;
