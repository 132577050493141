import React, { Component } from "react";
import TableSearchBar from "../Common/SearchBar/SearchBar";
import ScanMaster from "./ScanMaster/ScanMaster";
import StoreMaster from "./StoreMaster/StoreMaster";
import CategoryMaster from "./CategoryMaster/CategoryMaster";
import ProductMaster from "./ProductMaster/ProductMaster";
import {
  getStoreListing,
  uploadFile,
  getConceptListing,
  getRetailerListing,
  getCategoryListing,
  getScanMasterList,
  deleteCategory,
  getProductMasterListing,
  getLoginUserPermisionRoleData,
} from "../../Services/Store&Product/action";
import { loginUser } from "../../Services/Authentication/action";
import Utils from "../../Utils/Utils";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import LocalStorageServices from "../../Services/LocalStorage";
import {
  USERNAME,
  ACTIVE_TAB,
  imageConstants,
  LANGUAGE,
} from "../../config/constants";
import Img from "react-image";
import "./Home.css";
import UserPermission from "./UserPermission/UserPermission";

const { translate } = Utils;
class Home extends Component {
  constructor(props) {
    super(props);
    this.getLoginUserPermisionRoleData();
    this.state = {
      username: "",
      passwordText: "",
      activityStartFlag: false,
      uploadStatus: false,
      showFileUploadStatus: false,
      statusText: null,
      failedRecords: null,
      productMasterFlag: false,
      activeTab: parseInt(LocalStorageServices.getItem(ACTIVE_TAB)) || 0,
      loginUserPermisionRole: null,
      loginUserEditName: null,
    };
    this.getListingData();
    // this.getScanMasterData();
    this.getModalData();
    this.setLanguage();
  }

  componentDidMount() {
    if (
      this.props.loginUserRoleData?.userRole !== "SUPER_ADMIN"
      // &&
      // LocalStorageServices.getItem(USERNAME) ==
      //   this.props?.loginUserRoleData?.userName
      // (this.props.loginUserRoleData?.userName || "")
      //   .split("@")[0]
      //   .toLowerCase()
    ) {
      this.switchTab(0);
    }
  }

  handleLogout() {
    LocalStorageServices.removeAllItem();
    this.props.history.replace("/");
  }

  getListingData() {
    const { getListingData } = this.props;
    getListingData((status, response) => {
      //
    });
  }

  getLoginUserPermisionRoleData() {
    const { getLoginUserPermisionRoleData } = this.props;
    getLoginUserPermisionRoleData((status, response) => {
      this.setState({ loginUserPermisionRole: response?.userRole });
    });
  }

  setLanguage() {
    const selectedLanguage = LocalStorageServices.getItem(LANGUAGE);
    translate.setLanguage(selectedLanguage);
  }

  // getScanMasterData() {
  //   const { getScanMasterData } = this.props;
  //   getScanMasterData(5,0,(status, response) => {
  //     //
  //   });
  // }

  getModalData() {
    const { getConceptData, getRetailerData, getCategoriesData } = this.props;
    getConceptData();
    getRetailerData();
    getCategoriesData();
  }

  onFileUpload(event) {
    this.setState({ statusText: null, showFileUploadStatus: false });
    const { uploadProductFile, getProductMaster } = this.props;
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    event.target.value = "";
    var formData = new FormData();
    this.setState({ uploadStatus: true });
    formData.append("file", file);
    this.setState({ productMasterFlag: true });
    uploadProductFile(formData, (status, res) => {
      this.setState({ uploadStatus: false });
      if (status) {
        try {
          if (res.numberOfRecordsPassedPerTotal) {
            let statusText = `${res.numberOfRecordsPassedPerTotal.importedRecords} ${translate.ofText} ${res.numberOfRecordsPassedPerTotal.totalRecords}`;

            this.setState({
              statusText,
              showFileUploadStatus: true,
              failedRecords: res.failedProductDetails,
            });
            getProductMaster((status) => {
              if (status) {
                this.setState({ productMasterFlag: status });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.setState({
          statusText: false,
          showFileUploadStatus: true,
          productMasterFlag: true,
        });
      }
    });
  }

  onChangeText = (event) => {
    this.setState({ username: event.target.value });
  };

  onChangePasswordText = (event) => {
    this.setState({ passwordText: event.target.value });
  };

  switchTab(activeTab) {
    this.setState({ activeTab });
    LocalStorageServices.setItem(ACTIVE_TAB, activeTab);
  }

  loginUserEditName = (newName) => {
    this.setState({ loginUserEditName: newName });
  };

  renderSwitch() {
    const {
      storeListingData,
      categoriesListingData,
      conceptListingData,
      deleteCategory,
      getCategoriesData,
    } = this.props;
    const {
      activeTab,
      uploadStatus,
      showFileUploadStatus,
      statusText,
      failedRecords,
      productMasterFlag,
    } = this.state;
    LocalStorageServices.setItem(ACTIVE_TAB, activeTab);
    switch (activeTab) {
      case 0:
        return <ScanMaster conceptData={conceptListingData} />;
      case 1:
        return (
          <StoreMaster
            storeData={storeListingData}
            conceptData={conceptListingData}
          />
        );
      case 2:
        return (
          <CategoryMaster
            getCategoriesData={getCategoriesData}
            deleteCategory={deleteCategory}
            categoriesData={categoriesListingData}
          />
        );
      case 3:
        return (
          <ProductMaster
            onFileUpload={(event) => this.onFileUpload(event)}
            failedRecords={failedRecords}
            uploadStatus={uploadStatus}
            statusText={statusText}
            productMasterFlag={productMasterFlag}
            resetState={() => {
              this.setState({ statusText: false, showFileUploadStatus: false });
            }}
            showFileUploadStatus={showFileUploadStatus}
          />
        );
      case 4:
        return (
          <UserPermission
            getCategoriesData={getCategoriesData}
            deleteCategory={deleteCategory}
            categoriesData={categoriesListingData}
            conceptListingData={conceptListingData}
            loginUserPermisionRole={this.props.loginUserRoleData?.userRole}
            loginUserPermisionName={this.props.loginUserRoleData?.userName}
            loginUserEditName={this.loginUserEditName}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="main">
        <div className="sidenav">
          <div className="header-container">
            <Img
              style={{
                width: 120,
                margin: 20,
              }}
              src={imageConstants.axfoodLogo}
            />
          </div>
          <div className="imageContainer">
            <Img
              className="border-image"
              style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                marginBottom: 20,
              }}
              src={imageConstants.avatarIcon}
              alt="Avatar"
            />
            <p className="username">
              {LocalStorageServices.getItem(USERNAME) || ""}
            </p>
          </div>
          <div className="list-container">
            <span
              onClick={() => this.switchTab(0)}
              className={activeTab === 0 ? "list-active" : "list"}
            >
              <Img
                style={{
                  width: 27,
                  height: 20,
                  marginLeft: 7,
                }}
                decode={false}
                src={
                  activeTab === 0
                    ? imageConstants.barcodeActive
                    : imageConstants.barcodeIcon
                }
              />
              <span className="list-heading">{translate.scanMasterText}</span>
            </span>
            <span
              onClick={() => this.switchTab(1)}
              className={activeTab === 1 ? "list-active" : "list"}
            >
              <Img
                style={{
                  width: 27,
                  height: 20,
                  marginLeft: 7,
                }}
                decode={false}
                src={
                  activeTab === 1
                    ? imageConstants.storeMasterActive
                    : imageConstants.storeMasterIcon
                }
              />
              <span className="list-heading">{translate.storeMasterText}</span>
            </span>
            <span
              onClick={() => this.switchTab(2)}
              className={activeTab === 2 ? "list-active" : "list"}
            >
              <Img
                style={{
                  width: 27,
                  height: 18,
                  marginLeft: 7,
                }}
                decode={false}
                src={
                  activeTab === 2
                    ? imageConstants.categoryMasterActive
                    : imageConstants.categoryMasterIcon
                }
              />
              <span className="list-heading">
                {translate.categoryMasterText}
              </span>
            </span>
            <span
              onClick={() => this.switchTab(3)}
              className={activeTab === 3 ? "list-active" : "list"}
            >
              <Img
                style={{
                  width: 27,
                  height: 24,
                  marginLeft: 7,
                }}
                decode={false}
                src={
                  activeTab === 3
                    ? imageConstants.productMasterActive
                    : imageConstants.productMasterIcon
                }
              />
              <span className="list-heading">
                {translate.productMasterText}
              </span>
            </span>
            {this.props.loginUserRoleData?.userRole === "SUPER_ADMIN" && (
              <span
                onClick={() => this.switchTab(4)}
                className={activeTab === 4 ? "list-active" : "list"}
              >
                <Img
                  style={{
                    width: 27,
                    height: 18,
                    marginLeft: 7,
                  }}
                  decode={false}
                  src={
                    activeTab === 4
                      ? imageConstants.categoryMasterActive
                      : imageConstants.categoryMasterIcon
                  }
                />
                <span className="list-heading">{translate.userPermision}</span>
              </span>
            )}
          </div>
          {/* <div className='eclipse' /> */}
        </div>
        {activeTab === 1 || activeTab === 3 || activeTab === 0 ? null : (
          <TableSearchBar
            disabled={true}
            onLogoutClick={this.handleLogout.bind(this)}
          />
        )}
        {this.renderSwitch()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  storeListingData: state.storeReducer.storeListing,
  categoriesListingData: state.storeReducer.categoryListing,
  conceptListingData: state.storeReducer.conceptDataArray,
  loginUserRoleData: state.storeReducer.loginUserRole,
});

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: bindActionCreators(loginUser, dispatch),
  getListingData: bindActionCreators(getStoreListing, dispatch),
  getConceptData: bindActionCreators(getConceptListing, dispatch),
  getRetailerData: bindActionCreators(getRetailerListing, dispatch),
  getCategoriesData: bindActionCreators(getCategoryListing, dispatch),
  getScanMasterData: bindActionCreators(getScanMasterList, dispatch),
  uploadProductFile: bindActionCreators(uploadFile, dispatch),
  deleteCategory: bindActionCreators(deleteCategory, dispatch),
  getProductMaster: bindActionCreators(getProductMasterListing, dispatch),
  getLoginUserPermisionRoleData: bindActionCreators(
    getLoginUserPermisionRoleData,
    dispatch
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
