export const GET_STORE_LISTING = "GET_STORE_LISTING";
export const GET_SCAN_MASTER = "GET_SCAN_MASTER";
export const GET_CATEGORY_LISTING = "GET_CATEGORY_LISTING";
export const GET_CONCEPT_LISTING = "GET_CONCEPT_LISTING";
export const GET_RETAILER_LISTING = "GET_RETAILER_LISTING";
export const PUT_STORE_DATA = "PUT_STORE_DATA";
export const PUT_CATEGORY_DATA = "PUT_CATEGORY_DATA";
export const GET_SCAN_PRODUCT = "GET_SCAN_PRODUCT";
export const POST_PRODUCT_STATUS = "POST_PRODUCT_STATUS";
export const GET_QUANTITY_LISTING = "GET_QUANTITY_LISTING";
export const GET_DISCOUNT_LISTING = "GET_DISCOUNT_LISTING";
export const PUT_FILE_DATA = "PUT_FILE_DATA";
export const DELETE_CATEGORY_DATA = "DELETE_CATEGORY_DATA";
export const GET_STORE_PRODUCTS = "GET_STORE_PRODUCTS";
export const GET_PRODUCT_SEARCH = "GET_PRODUCT_SEARCH";
export const GET_FILTERED_PRODUCT = "GET_FILTERED_PRODUCT";
export const GET_SCAN_PRODUCT_EXTRACT = "GET_SCAN_PRODUCT_EXTRACT";
export const GET_SCAN_PRODUCT_EXTRACT_NEW = "GET_SCAN_PRODUCT_EXTRACT_NEW";
export const SEARCH_PRODUCT_SCAN = "SEARCH_PRODUCT_SCAN";
export const SEARCH_SCAN_MASTER = "SEARCH_SCAN_MASTER";
export const GET_LOGIN_USER_ROLE = "GET_LOGIN_USER_ROLE";
